
import { Component } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class TableForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'number', type: InputType.Text, label: 'table.form.number', rules: 'required' },
      { name: 'sanifairDeviceId', type: InputType.Text, label: 'table.form.sanifairDeviceId' },
      { name: 'description', type: InputType.Language, label: 'table.form.description' },
      { name: 'active', type: InputType.Checkbox, label: 'table.table.active' },
    ];
  }

  public getData() {
    return { ...this.$refs.form.form };
  }
}
